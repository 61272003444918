/* global gtag */
class Track {
  constructor () {
    Array.from(document.querySelectorAll('.track')).map(el => {
      el.addEventListener('click', (e) => {
        this.trackClick(e)
      })
    })
  }

  trackClick (event) {
    var link = event.currentTarget
    if (!link.disabled) {
      var trackObj = {}
      trackObj.category = link.dataset.category
      trackObj.action = link.dataset.action
      trackObj.label = link.dataset.label
      trackObj.value = link.dataset.value
      trackObj.href = link.href
      trackObj.target = link.target
      if (trackObj.category && trackObj.action && trackObj.label) {
        this.trackEvent(trackObj)
        // If tracking enabled, allow it to run the link
        if (window.ga_loaded) {
          return false
        }
      }
    }
  }

  trackEvent (obj) {
    var trackObj = {
      'eventCategory': obj.category.toLowerCase(),
      'eventAction': obj.action.toLowerCase(),
      'eventLabel': obj.label,
      'noninteraction': false
    }

    switch (trackObj.eventCategory) {
      case 'section':
        trackObj.noninteraction = true
        break
    }

    if (typeof obj.value !== 'undefined' && obj.value !== null) {
      trackObj.eventValue = obj.value
    }

    var l = obj.label.toLowerCase()
    l = l.replace(/\//g, '-')
    trackObj.eventLabel = l.toLowerCase()

    // If analytics are blocked, still follow links
    if (obj.href) {
      if (obj.target) {
        window.open(obj.href, obj.target)
      } else {
        document.location = obj.href
      }
    }

    // Cancel normal link to avoid double-popups
    if (obj.href && obj.target) return false
  }
}

export default new Track()

/* vi: set shiftwidth=2 tabstop=2 expandtab: */
